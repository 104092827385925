@import '/css/bootstrap-nucleus.css';
@import '/css/dracula.css';

html {
  height: 100%;
}

body {
  background-color: $colorDarkBlue;
  color: $colorWhite;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxyen,
    Ubuntu, Cantarell, 'Droid Sans', 'Fira Sans', 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  min-height: 100vh;
  position: relative;
}

a {
  color: inherit;
  transition: $transitionDefault;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

img,
video {
  max-width: 100%;
}

.BodyCopy {
  letter-spacing: 0.025rem;
  line-height: 1.75rem;
}

.Button {
  border: 0;
  border-radius: $borderRadius;
  cursor: pointer;
  display: block;
  font-size: 1.15rem;
  // margin: 0 auto;
  // padding: 1rem 8rem;
}

.Button--blueGrey {
  background-color: $colorBlueGrey;
  color: $colorGreen;
}

.Button--inline {
  cursor: pointer;
  transition: $transitionDefault;
}

.Button--inline--blue {
  color: $colorLightBlue;
  &:hover {
    color: $colorGreen;
  }
}

.ContentWrapper {
  padding-top: 5rem;
  padding-bottom: 2rem;
  @media screen and (min-width: $grid-md) {
    padding-top: 5.5rem;
  }
  @media screen and (min-width: $grid-xl) {
    padding-top: 6rem;
  }
}

.Intro-wrapper {
  padding-bottom: 2rem;
  @media screen and (min-width: $grid-xl) {
    padding-bottom: 3rem;
  }
}

.Intro-wrapper--sm {
  padding-bottom: 2rem;
}

.Intro-title {
  font-size: 1.5rem;
  font-weight: 400;
  @media screen and (min-width: $grid-md) {
    font-size: 1.75rem;
  }
  @media screen and (min-width: $grid-lg) {
    font-size: 2rem;
  }
  @media screen and (min-width: $grid-xl) {
    font-size: 2.5rem;
  }
}

.Intro-title--borderBottom {
  position: relative;
  margin-bottom: 3rem;
  &::after {
    background-color: $colorBlueGrey;
    content: '';
    margin: 0 auto;
    position: absolute;
    right: 0;
    bottom: -1rem;
    left: 0;
    width: 33%;
    height: $borderRadius;
  }
}

.Intro-title--pink {
  color: $colorPink;
}

.Intro-subtitle {
  @extend .BodyCopy;
}

.Logo-text {
  display: inline-block;
  font-weight: 400;
  letter-spacing: 0.025rem;
}

.Logo-text--nav {
  font-size: 1.25rem;
  margin: 0 0 0 0.5rem;
}

.Logo-text--footer {
  font-size: 1rem;
  margin: 0 0 0 0.75rem;
}

.Logo-brand {
  margin-bottom: 2rem;
  width: 200px;
  max-height: 200px;
}

.Topic-title {
  color: $colorGreen;
  font-size: 2rem;
  font-weight: 400;
  position: relative;
  margin-bottom: 4rem;
  text-align: center;
  &::after {
    background-color: $colorBlueGrey;
    content: '';
    margin: 0 auto;
    position: absolute;
    right: 0;
    bottom: -1.5rem;
    left: 0;
    width: 100%;
    height: $borderRadius;
  }
}
