/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: $colorLightBlue $colorBlueGrey;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
*::-webkit-scrollbar-track {
  background: $colorBlueGrey;
}
*::-webkit-scrollbar-thumb {
  background-color: $colorLightBlue;
  border-radius: 20px;
  border: 3px solid $colorBlueGrey;
}
