$colorBlueGrey: #354258;
$colorDarkBlue: #2d3748;
$colorLightBlue: #accdd2;
$colorGreen: #61ffab;
$colorPink: #ee78bf;
$colorWhite: mintcream;

$fontStackMono: 'Fira Code', 'Monaco', 'Consolas', monospace;

$borderRadius: 4px;

$grid-sm: 576px;
$grid-md: 768px;
$grid-lg: 992px;
$grid-xl: 1200px;

$navbarHeight: 50px;

$transitionDefault: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
$transitionSnappy: all 0.3s cubic-bezier(0.07, 0.95, 0, 1);
