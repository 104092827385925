article {
  a {
    @extend .green;
    @extend .hover\:green;
    @extend .hover\:underline-pink;
  }
  padding-bottom: 2rem;
  & blockquote {
    background-color: $colorBlueGrey;
    border-left: $borderRadius solid $colorGreen;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    margin: 2rem 0;
    padding: 1rem 1rem 1rem 2rem;
    & p {
      font-style: italic;
      margin: 0;
    }
  }
  & em {
    font-style: italic;
  }
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }
  & h1 {
    color: $colorGreen;
    font-size: 2rem;
    line-height: 1.25;
    margin: 1rem 0;
    @media screen and (min-width: $grid-md) {
      font-size: 2.5rem;
    }
  }
  & h2 {
    color: $colorPink;
    font-size: 1.66rem;
    margin-top: 3rem;
  }
  & h3 {
    font-size: 1.125rem;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    & + p {
      margin-top: 1rem;
    }
  }
  & h3,
  h5,
  h6 {
    color: $colorLightBlue;
  }
  h4 {
    color: $colorGreen;
    font-family: $fontStackMono;
    letter-spacing: 0.025rem;
  }
  & p {
    @extend .BodyCopy;
    font-size: 1.125rem;
    line-height: 2;
    margin: 2rem 0;
  }
  & strong {
    font-weight: 500;
  }
  & ul {
    margin-bottom: 2rem;
  }
  & ul > li {
    @extend .BodyCopy;
    font-size: 1.125rem;
    list-style-type: none;
    margin-bottom: 0.5rem;
    position: relative;
    &::after {
      background-color: $colorGreen;
      border-radius: 100%;
      content: '';
      position: absolute;
      top: 38%;
      left: -2rem;
      width: 8px;
      height: 8px;
    }
  }
}

hr {
  background-color: $colorBlueGrey;
  border: 0;
  height: $borderRadius;
}

code,
pre {
  font-family: $fontStackMono;
  font-size: 1rem;
  white-space: pre;
}

pre {
  background-color: #282a36;
  border-radius: $borderRadius;
  line-height: 2rem;
  margin: 0;
  padding: 1rem;
  & code {
    margin-right: 1rem;
  }
}

table {
  border-collapse: collapse;
  display: table;
  margin-bottom: 10px;
  width: 100%;
}

thead {
  border-bottom: 1px solid $colorBlueGrey;
}

td,
th {
  padding: 6px;
}

td code.highlighter-rouge {
  background-color: unset;
  border-radius: unset;
  padding: 0;
}

tbody tr:nth-child(2n) {
  background-color: $colorBlueGrey;
}

div.highlight {
  border-radius: $borderRadius;
  margin-bottom: 2rem;
}

.article-byline {
  font-size: 1.25rem;
  line-height: 1.25;
  margin: 1rem 0 2rem 0;
}

.Post-author {
  color: $colorLightBlue;
  display: inline-block;
  font-size: 1.125rem;
  margin: 0 1rem 0 0;
}

.Post-date {
  color: $colorLightBlue;
  display: inline-block;
  font-size: 1.125rem;
  margin: 0;
}

// inline code manually styled
code.highlighter-rouge {
  background: #282a36;
  border-radius: $borderRadius;
  color: #f8f8f2;
  padding: 0.075rem 0.5rem;
}

.toc-item {
  transition: $transitionDefault;
  &:hover {
    color: $colorGreen;
  }
}
